import React from "react";
import './contact.css'
import { BsFillTelephoneFill } from "react-icons/bs";
import { IoMdMail } from "react-icons/io";
import { MdAccessTimeFilled } from "react-icons/md";
import { MdLocationPin } from "react-icons/md";


const Contact = () => {
  return (
    <div className="contact-container">
      <h2 className="title">
        COntact <br/> <span>Quik Delivery LLC</span>
      </h2>
      
      <div className="contact-box">
      <div className="box">
              <div className="icon">
                <BsFillTelephoneFill />
              </div>
              <a href="tel:+17076061092">+1 707-606-1092</a>
            </div>
            <div className="box">
              <div className="icon">
                <IoMdMail size={20} />
              </div>
              <a href="mailto:jobs@quikdsp.com">jobs@quikdsp.com</a>
            </div>
            <div className="box">
              <div className="icon">
                <MdAccessTimeFilled />
              </div>
              <a href="#f">
                Active Hours <br /> <span>10 AM to 5 PM</span>
              </a>
            </div>
            <div className="box">
              <div className="icon">
                <MdLocationPin />
              </div>
              <a
                href="https://goo.gl/maps/yTGaSB1BzATJjXdv5"
                rel="noreferrer"
                target="_blank"
              >
                Work address
                <br />
                <span>DFO9 - 300 Boone Dr, American Canyon, CA 94503, USA.</span>
              </a>
            </div>
        
      </div>
    </div>
  );
};

export default Contact;
