import React from 'react'
import logo from "../img/SVG/logo.svg";
import { Link } from 'react-router-dom';
import { AiFillInstagram } from 'react-icons/ai';
import { BsFacebook } from 'react-icons/bs';
import { BsLinkedin } from 'react-icons/bs';
import './footer.css'

function Footer() {
  return (
    <section className='footer_parent'>
      <div className="footer">
        <div className="col1 col">
          <div className="logo"><img src={logo} alt="site logo" />QUIK Delivery llc</div>
          <p className='desc'> Collaborative, Safe, and Customer-Centric. Shape the Future of Last-Mile Deliveries.</p>
          <div className="social">
           
            <a href="https://www.facebook.com/Quik-Delivery-103513606126735/" target='_blank' rel='noreferrer'><BsFacebook/></a>
            <a href="https://www.instagram.com/quik.dsp/" target='_blank' rel='noreferrer'><AiFillInstagram/></a>
            <a href="https://www.linkedin.com/company/quikdsp/" target='_blank' rel='noreferrer'><BsLinkedin/></a>
          </div>
          
        </div>
        <div className="col2 col">
          <div className="footer-header">
            Links
          </div>
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/contact">Contact</Link>
        </div>
        <div className="col3 col">
        <div className="footer-header">
            Locate US
          </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1567.6292607324297!2d-122.2709794!3d38.2038943!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80850942c84d0afd%3A0x1e2870d466f8e80b!2samazon!5e0!3m2!1sen!2sin!4v1687858758902!5m2!1sen!2sin" title='map'  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">

        </iframe>
        </div>
      </div>
      <div className="copy"> Copyright @ Quik Delivery LLC 2023   | Designed & developed by <a href="https://igrowmybiz.com/" target='_blank' rel='noreferrer'>Igrowmybiz</a></div>
    </section>
  )
}

export default Footer;