import React from "react";
import { Link } from "react-router-dom";
import "./about.css";
import aboutimg1 from "../img/otherstock/bg3.webp";

const About = () => {
  return (
    <div className="about-container">
      <div className="about-box">
        <div className="left">
          <h2>
            {" "}
            About<span> Quik Delivery LLC</span>{" "}
          </h2>
          <p>
            At Quik Delivery LLC, we are driven by a vision of the future, where
            collaboration, communication, and customer focus are the pillars of
            last-mile logistics. Our unwavering commitment to safety and quality
            ensures that our drivers return home safely while exceeding customer
            expectations. We understand that satisfied customers are the key to
            long-term success, and that's why we prioritize exceptional service
            that goes above and beyond. Our dedication to safety measures not
            only ensures our long-term viability but also leads to lower
            insurance costs in the future.
             <p>Moreover, we believe that a company's
            culture is vital for success. At Quik Delivery LLC, we foster a respectful and
            supportive environment that values our employees, empowering them to
            deliver the best service possible. As our customer base expands, we
            constantly adapt and look ahead to meet their evolving needs. We
            align with Amazon's policies and compliance standards, maintain
            strong communication with their OTR team, and proactively network
            with nearby vendors to resolve issues promptly.</p> 
            The term 'Quik'
            perfectly reflects our commitment to efficient, reliable, and
            on-time delivery while upholding the highest quality and safety
            standards. Join us at Quik Delivery LLC, where we embrace the future and
            deliver excellence at every step.
          </p>

          <Link to="/contact" className="btn-main">
            contact US
          </Link>
        </div>
        <div className="right">
          <img src={aboutimg1} alt="" />
        </div>
      </div>
    </div>
  );
};

export default About;
