import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./homepage.css";
import deliveryboy from "../img/otherstock/bg2.webp";
import { MdHealthAndSafety } from "react-icons/md";
import { FaGrinStars } from "react-icons/fa";
import { MdOutlineAutoGraph } from "react-icons/md";
import { LuPackageOpen } from "react-icons/lu";
import { MdExpandMore } from "react-icons/md";

const Homepage = () => {
  const [state, setstate] = useState(false);
  const [state1, setstate1] = useState(true);
  const [state3, setstate3] = useState(true);
  const [state4, setstate4] = useState(true);
  const handleevent = () => {
    if (state === true) {
      setstate(false);
      setstate1(true);
      setstate3(true);
      setstate4(true);
    } else if (state === false) {
      setstate(true);
    }
  };
  const handleevent1 = () => {
    if (state1 === true) {
      setstate(true);
      setstate1(false);
      setstate3(true);
      setstate4(true);
    } else if (state1 === false) {
      setstate1(true);
    }
  };

  const handleevent3 = () => {
    if (state3 === true) {
      setstate(true);
      setstate1(true);
      setstate3(false);
      setstate4(true);
    } else if (state3 === false) {
      setstate3(true);
    }
  };
  const handleevent4 = () => {
    if (state4 === true) {
      setstate(true);
      setstate1(true);
      setstate3(true);
      setstate4(false);
    } else if (state4 === false) {
      setstate4(true);
    }
  };

  return (
    <div className="homepage">
      <section className="sec1">
        <div className="hero-box">
          <div className="left">
            <h1 className="heading">
              Join Quik Delivery LLC  <br />{" "}
              <span> the best amazon DSP</span>
            </h1>
            <p className="herptext">
              {" "}
              Be part of an innovative last-mile logistics company that values
              collaboration, safety, and exceptional customer service. Quik
              Delivery LLC is dedicated to exceeding customer expectations while
              ensuring the safety and well-being of our delivery partners. Join
              us today and shape the future of efficient and reliable last-mile
              deliveries.
            </p>

            <div className="btn-box">
              <Link to="/contact" className="btn-main">
                Contact Us
              </Link>
              <Link to="/apply" className=" bt-main btn-invert">
                Apply Now{" "}
              </Link>
            </div>
          </div>
          <div className="right">
            <img src={deliveryboy} alt="" />
          </div>
        </div>
      </section>
      <section className="sec2">
        <div className="wcu-box">
          <div className="top">
            <h2 className="title">
              WHy <span>Choose us</span>
            </h2>
            <p>
              Reliable deliveries. Exceptional service. Cutting-edge technology. Commitment to safety.
            </p>
          </div>
          <div className="bottom">
            {/* first box */}

            <div className="smallbox">
              <div className="icon">
                <MdHealthAndSafety size={70} />
              </div>
              <div className="text">
                <h3>Safety First</h3>
                <p className="why">
                  {" "}
                  Ensure driver well-being and community safety through
                  stringent safety measures.
                </p>
              </div>
            </div>

            {/* second box */}

            <div className="smallbox">
              <div className="icon">
                <FaGrinStars size={65} />
              </div>
              <div className="text">
                <h3>Customer Satisfaction</h3>
                <p className="why">
                  {" "}
                  Exceed expectations with exceptional service, building
                  long-term success through satisfied customers.
                </p>
              </div>
            </div>

            {/* third box */}
            <div className="smallbox">
              <div className="icon" style={{ animationDelay: "0.3s" }}>
                <MdOutlineAutoGraph size={60} />
              </div>
              <div className="text">
                <h3>Future-Focused</h3>
                <p className="why">
                  {" "}
                  Embrace technology and innovation to lead the way in last-mile
                  delivery solutions.
                </p>
              </div>
            </div>
            {/* fourth box */}
            <div className="smallbox">
              <div className="icon">
                <LuPackageOpen size={65} />
              </div>
              <div className="text">
                <h3>Delivery Associate Opportunities</h3>
                <p className="why">
                Join Quik Delivery LLC as a delivery associate and become an integral part of our dynamic team.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec2-1">
        <div className="mission-container">
          <div className="top">
            <h2 className="title">
            Quik Delivery<span> Vision & Mission </span>
            </h2>
          </div>
          <div className="bottom">
            <div className="mission-left">
              <div className="mission-box">
                <div className="topic">Safety</div>
                <div className="data">
                  <ul>
                    <li id="">Employee Safety</li>
                    <li id="">Community Safety</li>
                    <li id="">Fleet Safety</li>
                  </ul>
                </div>
              </div>
              <div className="mission-box">
                <div className="topic">
                  Quality of <br />
                  Service
                </div>
                <div className="data">
                  <ul>
                    <li id="">Timely Delivery</li>
                    <li id="">Packages Delivered Intact</li>
                    <li id="">Fantastic Customer Delivery Feedback</li>
                  </ul>
                </div>
              </div>
              <div className="mission-box">
                <div className="topic">Agility</div>
                <div className="data">
                  <ul>
                    <li id="">Seasonal Delivery</li>
                    <li id="">New Initiatives e.g., Electrical Vehicles </li>
                    <li id="">Changes on Guidelines and Compliance</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="mission-right">
              <div className="vision-box">
                <h2 className="title">
                  OUR <span>VISION</span>
                </h2>
                <p>
                  To be the best logistics provider with highest quality
                  standards and customer delight.{" "}
                </p>
              </div>
              <div className="vision-box">
                <h2 className="title">
                  OUR <span>MISSION</span>
                </h2>
                <p>
                Establishing a new standard of excellence in logistics services, our mission is to deliver reliable unparalleled solutions that prioritize safety, quality, and agility. With a strong commitment to professionalism and our core values of integrity, authenticity, and purposeful action, we aim to exceed customer expectations by providing timely and quality deliveries. We are dedicated to upholding the well-being of our employees, the environment, and the communities we serve. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec3">
        <div className="gallery-box">
          <div className="top">
            <h2 className="title">
              Our <span>Gallery</span>
            </h2>
          </div>
          <div className="bottom">
            <div className="imgss-box">
              <div className="slide"></div>
              <div className="slide"></div>
              <div className="slide"></div>
              <div className="slide"></div>
              <div className="slide"></div>
              <div className="slide"></div>
              <div className="slide"></div>
              <div className="slide"></div>
              <div className="slide"></div>
              <div className="slide"></div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec4">
        <div className="faq-box">
          <div className="top">
            <h2 className="title">
              Some <span>FAQs</span>
            </h2>
          </div>
          <div className="bottom">
            <div
              className={state ? "faq faq-close" : "faq faq-open"}
              onClick={handleevent}
            >
              <h3>
                How can I become a Delivery Associate with Quik Delivery LLC?
              </h3>
              <p>
                To become a Delivery Associate with Quik Delivery LLC, please
                visit our Careers page and fill out the application form. Our
                team will review your application and contact you regarding the
                next steps.
              </p>
              <div className="arrow">
                <MdExpandMore />
              </div>
            </div>
            <div
              className={state1 ? "faq faq-close" : "faq faq-open"}
              onClick={handleevent1}
            >
              <h3>
                What safety measures does Quik Delivery LLC have in place for Delivery Associate ?
              </h3>
              <p>
                Quik Delivery LLC prioritizes the safety of our Delivery Associates. We
                implement rigorous safety protocols, provide comprehensive
                training. We also conduct regular vehicle maintenance and ensure compliance
                with traffic regulations.
              </p>
              <div className="arrow">
                <MdExpandMore />
              </div>
            </div>

            <div
              className={state3 ? "faq faq-close" : "faq faq-open"}
              onClick={handleevent3}
            >
              <h3>
                How is the work culture at Quik Delivery LLC ?
              </h3>
              <p>
                
                Quik Delivery LLC has a collaborative work culture. It`s a place where teamwork and communication are valued the most.
              </p>
              <div className="arrow">
                <MdExpandMore />
              </div>
            </div>
            <div
              className={state4 ? "faq faq-close" : "faq faq-open"}
              onClick={handleevent4}
            >
              <h3>
              What are the requirements to become a Delivery Associate with Quik Delivery LLC ?
              </h3>
              <p>
              Quik Delivery LLC has specific requirements for Delivery Associates, including a valid driver's license, driving experience and a good driving record.
              </p>
              <div className="arrow">
                <MdExpandMore />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Homepage;
