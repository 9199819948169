import { BrowserRouter,Routes , Route } from 'react-router-dom';
import './App.css';
import Header from './other pages/Header';
import Homepage from './other pages/Homepage';
import About from './other pages/About';
import Contact from './other pages/Contact';
import Footer from './other pages/Footer';
import Apply from './other pages/Apply';

function App() {
  return (
    <div className="App">
    <BrowserRouter>
    <Header/>
    <Routes>
      <Route path='/' Component={Homepage} /> 
      <Route path='/about' Component={About} /> 
      <Route path='/contact' Component={Contact} /> 
      <Route path='/apply' Component={Apply} /> 
    </Routes>
<Footer/>
    </BrowserRouter>
      
    </div>
  );
}

export default App;
