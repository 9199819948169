import React ,{useState} from 'react'
import { Link } from 'react-router-dom'
import { HiMenuAlt2 } from 'react-icons/hi'
import { GrClose } from 'react-icons/gr';
import logo from '../img/SVG/logo.svg'
import './header.css'



const Header = () => {


    const [isopen , setopen]  = useState(false);





    return (
        <div className='header-parent'>
            <div className="header-container">
                <Link to='/' className="logo"><img src={logo} alt="" /></Link>
                <div className="links">
                    <Link to='/'>Home</Link>
                    <Link to='/about' >About</Link>
                    <Link to='/contact' >Contact</Link>
                </div>
                <div className="btn-container">
                    <Link className='btn-main' to='/apply'>Apply Now</Link>
                </div>
                <div className="menu"  onClick={ ()=> {
                    setopen(true)
                }}> <HiMenuAlt2/></div>

            </div>


            <div className={ isopen ? 'openall' : 'closeall'} id='header-mob' >

                <div className="close" onClick={ ()=> {
                    setopen(false)
                }} >
                    <GrClose />
                </div>

                <div className="links-mob">
                    <Link to='/'  onClick={ ()=> {
                    setopen(false)
                }} >Home</Link>
                    <Link to='/about'   onClick={ ()=> {
                    setopen(false)
                }} >About</Link>
                    <Link to='/contact'  onClick={ ()=> {
                    setopen(false)
                }} >Contact</Link>
                    <Link to='/apply' onClick={ ()=> {
                    setopen(false)
                }} >Apply Now</Link>

                </div>
            </div>


        </div>
    )
}

export default Header