import React, { useRef } from "react";
import Swal from "sweetalert2";
import "./apply.css";

const Apply = () => {
  const inputRef = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const inputRef5 = useRef(null);
  const inputRef6 = useRef(null);
  const inputRef7 = useRef(null);
  const Submit = (e) => {
    const formele = document.querySelector("form");
    e.preventDefault();
    const formData = new FormData(formele);
    fetch(
      "https://script.google.com/macros/s/AKfycbwj8TedyKI9pRHrLoo4bjewrFonzCiWt_JGkD0FM5dJeAqk7ai-lI9UxITFh7c591Dm/exec",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => console.log(error));

    Swal.fire("Thank you", "form submitted", "success");

    inputRef.current.value = "";
    inputRef2.current.value = "";
    inputRef3.current.value = "";
    inputRef4.current.value = "";
    inputRef5.current.value = "";
    inputRef6.current.value = "";
    inputRef7.current.value = "";

    
  };

  return (
    <div className="apply-container">
      <h2 className="title">
        Join <span>Quik Delivery LLC</span>
      </h2>

      <div className="apply-box">
        <div className="left">
          <p className="strong">
            Please fill this form to get a call back form out team
          </p>
          <form action="" onSubmit={(e) => Submit(e)}>
            <input ref={inputRef6} type="date" />
            <input ref={inputRef7} type="time" />
            <div className="row">
              <input
                ref={inputRef}
                type="text"
                name="FirstName"
                id=""
                placeholder="First Name"
              />
              <input
                ref={inputRef2}
                type="text"
                name="LastName"
                id=""
                placeholder="Last Name"
              />
            </div>
            <input
              ref={inputRef3}
              type="email"
              name="Email"
              id=""
              placeholder="Email"
            />

            <input
              ref={inputRef4}
              type="phone"
              name="Phone"
              id=""
              placeholder="Phone"
            />
            <textarea
              ref={inputRef5}
              name="Message"
              id=""
              cols="30"
              rows="10"
              placeholder="Your Message goes here...."
            ></textarea>
            <input type="submit" name="Conact Us" id="" />
          </form>
        </div>
        <div className="right">
          <p className="strong">
            Apply via<span> INDEED</span>
          </p>
          <p> Click the link below to apply vai Indeed</p>

          <a
            href="https://www.indeed.com/cmp/Quik-Delivery-LLC?from=mobviewjob&tk=1h40thii0kc2h800&fromjk=ee6223e6ff488fad&attributionid=mobvjcmp"
            target="_blank"
            rel="noreferrer"
          >
            Apply NOW
          </a>
        </div>
      </div>
    </div>
  );
};

export default Apply;
